<template>
  <v-col cols="12"
        :md="cardsize === 2 ? 3 :cardsize === 3 ? 4 : undefined " 
        >
    <v-card
        dark
        :height="350">

        <router-link :to="{name: 'BlogEntry', params: {blogID: value.key}}">
            <template v-if="IDpictures[0]">
                <v-img
                :lazy-src="IDpictures[0].thumbUrl"
                :src="IDpictures[0].url"
                height="100%"
                >
                <v-col cols="12">
                    <h3 class=" black--text text-uppercase font-weight-light grey lighten-5">
                        {{value.title}}
                    </h3>
                    <h4 class = " black--text font-weight-light grey lighten-5">
                        {{value.date}}
                    </h4>
                </v-col>
                </v-img>
            </template>
            <template v-else>
                <v-col cols="12">
                    <h3 class=" black--text text-uppercase font-weight-light grey lighten-5">
                        {{value.title}}
                    </h3>
                    <h4 class = " black--text font-weight-light grey lighten-5">
                        {{value.date}}
                    </h4>
                </v-col>
            
            </template>
        </router-link>
    </v-card>
  </v-col> 
</template>


<script>

import { mapState } from 'vuex'
export default {
    name:'FeedCard',
    computed: { 
        ...mapState(['pictures']),
        IDpictures() {
            return this.$store.getters.articleLinkedPictures(this.value.key)
        },
        // sortedArticles() {
        //     return this.$store.getters.sortedArticles()
        // },
    },
    props: {
        value: {
            type: Object,
        },
        cardsize: {
            type: Number,
            required: true
        }
    }
}
</script>

<style >
*{
    text-decoration: none;
}
</style>